import React from 'react';
import { Box, Divider, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import data from './data.json'; // Import the JSON data
import SearchBar from '../SearchComponents/SearchBar';
import ItineraryCardLayout from './ItineraryCardLayout';


const ListItinerary: React.FC = () => {
	const navigate = useNavigate();

	const toShow = process.env.REACT_APP_ENV as string;
	// Extract the itineraries array from the imported data
	const itineraries: any[]
		= (toShow === "DEV") ?
			data.destinations.map((dest: { id: any; destinationName: any; dates: any; noOfAdults: any; imgSrc: any; }) => ({
				id: dest.id,
				destination: dest.destinationName,
				dates: dest.dates,
				numberOfAdults: dest.noOfAdults,
				imageSrc: dest.imgSrc
			})) : [];

	const handleEdit = (id: number) => {
		navigate(`/itineraries/${id}`);
		console.log(`Edit button clicked for itinerary with id: ${id}`);
	};

	return (

		<Box mt={4}>
			<SearchBar
				onSearch={() => { }}
			/>
			<VStack spacing={'20px'} w={"-moz-fit-content"} mt={'32px'}>
				{itineraries.map((itinerary) => (
					<><ItineraryCardLayout
						key={itinerary.id}
						imageSrc={itinerary.imageSrc}
						title={itinerary.destination}
						dates={itinerary.dates}
						numberOfAdults={itinerary.numberOfAdults}
						onEdit={() => handleEdit(itinerary.id)} />
						<Divider
							my={2} // Margin Y for spacing
							border={'1px solid #FFFFFF21'} /></>
				))}

			</VStack>
		</Box>

	);
};

export default ListItinerary;
