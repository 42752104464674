import {
  Box, IconButton, Image,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { pixels } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import * as amplitude from '@amplitude/analytics-browser';
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
// import NotificationBubble from "../Collection-flow/NotificationBubble";
import useAuthRouteProtector from "../../hooks/useAuthRouteProtector";

const ItineraryFooter: React.FC<{ isCollectionActive?: boolean }> = ({ isCollectionActive }) => {
  const router = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const { isAuthenticated } = useAuthRouteProtector();
  const context: any = useContext(AppContext);
  const { rOpen, setROpen, lOpen, setLOpen, setChatEntries,
    setIsCenterButtonClicked, isCenterButtonClicked
    // setIsCollection 
  } = context;


  useEffect(() => {
    if (location.pathname === "/") {
      setROpen(false);
      setLOpen(true);
    } else if (location.pathname === "/navigate") {
      setROpen(true);
      setLOpen(false);
    }
    else if (location.pathname === "/profile") {
      setROpen(false);
      setLOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleRightButton = () => {
    setLOpen(false);
    setIsCenterButtonClicked(false);
    if (rOpen) {
      setROpen(false);
      router("/");
    } else {
      if (isAuthenticated) {
        amplitude.track('bucketList_clicked', { page: 'HomePage', buttonName: 'bucketList' });
        setROpen(true);
        router("/navigate");
      } else {
        amplitude.track("login_triggered", { buttonName: "bucketList", page: "HomePage" });
        onOpen(); // Open the modal
      }

    }
  };
  const handleLeftButton = () => {
    setROpen(false);
    setIsCenterButtonClicked(false);
    if (lOpen) {
    } else {
      setLOpen(true);
      router("/");
    }
  };

  const handleCenterButton = () => {
    // setChatEntries([]);
    // setROpen(false);
    // setIsCollection(false);
    // setLOpen(false);
    // if (isAuthenticated) {
    //   router("/traveladvisor");
    // } else {
    //   onOpen(); // Open the modal
    // }

    router('/');
    setIsCenterButtonClicked(!isCenterButtonClicked);
    setChatEntries([]);
    setROpen(false);

    // setIsCollection(false);
    // setLOpen(false);
    console.log("center button clicked");
  };
  return (
    <>
      <VStack
        spacing={14}
        display={{ base: "flex", md: "none" }}
        w={'100%'}
        zIndex={11}
        bottom={0}
        position={'fixed'}
      >
        {/* {isCollectionActive && (
          <Box position={'relative'}>
            <NotificationBubble />
          </Box>)} */}

        <Box
          display={{ base: "flex", md: "none" }}
          bgImage={"/icons/Bottom_Sheet.svg"}
          backgroundPosition={"center"}
          justifyContent={"space-between"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
          alignItems={"center"}
          px={pixels["40pixels"]}
          w={"100%"}
          h="64px"
          position="relative"

        >
          {/* Left Side (Opaque) */}
          <IconButton
            onClick={handleLeftButton}
            aria-label={""}
            bg="none"

            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
          >
            <Image
              src={lOpen ? "/icons/homeClicked.svg" : "/icons/HomeUnClicked.svg"}
              alt="home"
            />
          </IconButton>

          {/* Middle Transparent Section with Circle */}
          <Box
            bg="transparent"
            h="100%"
            flexBasis="40%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box
              position="absolute"
              top="-30px" /* Adjust this to control how much the circle is raised above the footer */
              bg="transparent"
              h="100%"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* Circular middle item */}
              <IconButton
                onClick={handleCenterButton}
                borderRadius={"50%"}
                h="max-content"
                w="max-content"
                aria-label={""}
                position={"absolute"}
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                _focus={{ boxShadow: "none" }}
                bg="none"
              >
                <Image src="/icons/Centre_Button.svg" />
              </IconButton>
            </Box>
          </Box>

          {/* Right Side (Opaque) */}
          <IconButton
            aria-label={""}
            px={1}
            onClick={handleRightButton}
            bg="none"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
          >
            <Image
              src={
                rOpen ? "/icons/savedClicked.svg" : "/icons/ListUnselected.svg"
              }
            />
          </IconButton>
        </Box>
      </VStack>
      <Box zIndex={9999999999999999}>
        <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
          <SigninComponent onClose={onClose} />
        </ModalWithBackdrop>
      </Box>
    </>
  );
};

export default ItineraryFooter;
