import { Input, InputGroup, InputRightElement, Image, Box, useDisclosure, InputLeftElement } from "@chakra-ui/react";
import React, { useState } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";


interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestedBox, setShowSuggestedBox] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update the state with the input value
  };

  const handleSearch = () => {
      if (searchTerm.trim())
        onSearch(searchTerm); // Trigger the search when Enter is pressed or icon is clicked
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      amplitude.track('video_search_clicked', { type: "search_query", query_name: searchTerm, page: 'Homepage' });
      handleSearch();
    }
  };

  const suggestions = [
    'Japan travel recommendations',
    'Solo female travel to Japan ',
    'New Zealand in December',
    'Top things to do in Thailand ',
    'Adventure activities in Switzerland',
  ];

  return (
    <>
      <Box>

        <InputGroup mb={'1%'} mt={"2.1%"} w={'93.5vw'} onFocus={(e) => {
          if(localStorage.getItem('token')){
          setShowSuggestedBox(true)}
          else{
            (e.target as HTMLInputElement).blur();
            onOpen();
          }
        }} onBlur={() => setTimeout(() => setShowSuggestedBox(false), 200)}>
          <InputLeftElement
                        
                        // pl={6}
                        mt={'4px'}
                       children={
                        <Image
                            boxSize={'17px'}
                            src={'/icons/reel.png'}
                            alt="search"
                            cursor="pointer"
                            onClick={handleSearch} // Trigger search on icon click
                        />
                    } 
                />
          <Input
            py={'3%'}
            placeholder="Discover inspiring travel videos"
            _placeholder={{ color: "#B0B0B0" }}
            bg="#242127"
            color="#FFFFFF"
            borderRadius="12px"
            border={'1px solid #FFFFFF12'}
            boxShadow={'0px 1px 1px rgba(255, 255, 255, 0.32)'}
            h={'48px'}
            pl={9}
            value={searchTerm} // Controlled input
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Trigger search on Enter key press
          />
          <InputRightElement
            py={'25px'}
            mt={'1px'}
            children={
              <Image
                boxSize={'18px'}
                src={'/icons/search2.svg'}
                alt="search"
                cursor="pointer"
                onClick={() => {
                  amplitude.track('video_search_clicked', { type: "search_query", query_name: searchTerm, page: 'Homepage' });
                  handleSearch()
                }} // Trigger search on icon click
              />
            }
          />
        </InputGroup>
        {showSuggestedBox && (
          <Box
            position={'absolute'}
            mt={2}
            bg="#242127"
            color="gray.200"
            w={'92vw'}
            borderRadius="12px"
            border="1px solid #FFFFFF12"
            maxH="auto"
            p={2}
            overflowY="auto"
          >
            {suggestions.map((suggestion, index) => (
              <Box
                key={index}
                p={2}
                fontSize={'md'}
                // _hover={{ bg: "#3a3a3a", cursor: "pointer" }}
                onClick={() => {
                 
                    amplitude.track('video_search_clicked', { type: "suggested_query", query_name: suggestion, page: 'Homepage' });
                    setSearchTerm(suggestion);
                    onSearch(suggestion);
                }}
              >
                {suggestion}
              </Box>
            ))}
          </Box>
        )}

      </Box>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>
    </>
  );
};

export default SearchBar;
