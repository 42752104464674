import { useState, useRef, useCallback, useEffect } from 'react';

interface PWAInstallConfig {
  autoPrompt?: boolean;
  onEvent?: (eventName: PWAEvent, data?: any) => void;
}

type PWAEvent =
  | 'beforeinstallprompt'
  | 'appinstalled'
  | 'dismissed'
  | 'installed'
  | 'error'
  | 'iosinstallprompt';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

interface PWAInstallState {
  isInstallable: boolean;
  isStandalone: boolean;
  installationStatus: 'idle' | 'installing' | 'installed' | 'error';
  hasUserDismissed: boolean;
  error: Error | null;
  isIOS: boolean;
}

export const usePWAInstall = (config: PWAInstallConfig = {}) => {
  const { autoPrompt = false, onEvent } = config;

  const [state, setState] = useState<PWAInstallState>({
    isInstallable: false,
    isStandalone: false,
    installationStatus: 'idle',
    hasUserDismissed: false,
    error: null,
    isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream,
  });

  const deferredPromptRef = useRef<BeforeInstallPromptEvent | null>(null);

  const emitEvent = useCallback(
    (eventName: PWAEvent, data?: any) => onEvent?.(eventName, data),
    [onEvent]
  );

  const checkStandalone = useCallback(() => {
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://');

    setState((prev) => ({ ...prev, isStandalone }));
  }, []);

  const handleInstallClick = useCallback(async () => {
    if (state.isIOS) {
      emitEvent('iosinstallprompt');
      return;
    }

    const prompt = deferredPromptRef.current;
    if (!prompt) {
      const error = new Error('Installation prompt not available');
      emitEvent('error', error);
      setState((prev) => ({ ...prev, error, installationStatus: 'error' }));
      return;
    }

    try {
      setState((prev) => ({ ...prev, installationStatus: 'installing' }));
      await prompt.prompt();
      const { outcome } = await prompt.userChoice;

      emitEvent(outcome === 'accepted' ? 'installed' : 'dismissed');
      setState((prev) => ({
        ...prev,
        installationStatus: outcome === 'accepted' ? 'installed' : 'idle',
        hasUserDismissed: outcome === 'dismissed',
      }));
    } catch (error) {
      const err = error instanceof Error ? error : new Error('Installation failed');
      emitEvent('error', err);
      setState((prev) => ({ ...prev, error: err, installationStatus: 'error' }));
    }
    // eslint-disable-next-line 
  }, [state.isIOS]);

  useEffect(() => {
    checkStandalone();

    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      deferredPromptRef.current = e;
      emitEvent('beforeinstallprompt', e);
      setState((prev) => ({ ...prev, isInstallable: true }));

      if (autoPrompt) handleInstallClick();
    };

    const handleAppInstalled = () => {
      emitEvent('appinstalled');
      setState((prev) => ({ ...prev, isInstallable: false, installationStatus: 'installed' }));
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
    // eslint-disable-next-line 
  }, [autoPrompt, checkStandalone, handleInstallClick]);

  return {
    ...state,
    deferredPrompt: deferredPromptRef.current,
    handleInstallClick,
    resetError: useCallback(
      () => setState((prev) => ({ ...prev, error: null, installationStatus: 'idle' })),
      []
    ),
  };
};
