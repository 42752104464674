import React, { useState, useRef } from 'react';
import {
    Box,
    Text,
    Image,
    Container,
    FormControl,
    FormLabel,
    Input,
    VStack,
    HStack,
    useToast
} from '@chakra-ui/react';
import * as amplitude from '@amplitude/analytics-browser';
import PhoneInput from 'react-phone-number-input';
import TrPrimaryButton from '../components/TrPrimaryButton';
import { pixels } from '../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';


const Signup: React.FC = () => {
    const navigate = useNavigate();
    const { fetchData } = useAxios();
    const toast = useToast();
    const location = useLocation();

    const [phone, setPhone] = useState<string | undefined>();
    const [isInvalid, setIsInvalid] = useState(false);
    const emailRef = useRef<HTMLInputElement>(null);

    const handleSignUp = async () => {
        const email = emailRef.current?.value;

        if (!phone && !email) {
            toast({
                title: "Validation Error",
                description: "Please enter phone number or email",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            amplitude.track('waitlist_signup', { approved: "false", phone: phone, email: email });
            const { data } = await fetchData({
                method: "POST",
                url: "waitlist",
                options: {
                    data: { phone_number: phone, email: email?.length ? email : undefined, send_message: location.state?.send_message ?? true },
                    headers: { "Content-Type": "application/json" },
                },
                auth: false,
            });
            if(data?.user_id)
                amplitude.setUserId(data?.user_id);
            if (!data.is_waitlist) {
                navigate("/", { state: { alreadyUser: true } });
            } else if (data.message === "User already in waitlist") {
                toast({
                    title: "User already in waitlist",
                    description: "You are already in the waitlist.",
                    status: "info",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/");
            } else {
                toast({
                    title: "Success",
                    description: "Waitlist registered successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/");
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "An error occurred during sign-up. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error("An error occurred during sign-up", error);
        }
    };

    return (
        <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bgImage={`url("images/background-image.png")`}
            bgRepeat="no-repeat"
            bgPos="center"
            bgSize="cover"
        >
            <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
                <VStack spacing={4}>
                    <HStack>
                        <Image src="/icons/logo.svg" alt="logo" w="32.27px" />
                        <Text
                            fontFamily='Poppins'
                            fontSize='20.97px'
                            fontWeight='700'
                            lineHeight='32.59px'
                            letterSpacing='-0.34px'
                            textAlign='left'
                        >Rahee</Text>
                    </HStack>
                    <Text
                        fontFamily='Poppins'
                        fontSize='16px'
                        fontWeight='600'
                        lineHeight='24px'
                        mb={2}
                        letterSpacing='-0.26px'
                        textAlign='center'>
                        Sign up now to join the waitlist and get exclusive early access!</Text>
                    <FormControl id="phone">
                        <FormLabel>Phone Number</FormLabel>
                        <Box
                            sx={{

                                border: `1px solid #E2E8F0`, // White for valid, red for invalid
                                borderRadius: '6px',

                                display: 'flex',
                                py: '2',
                                alignItems: 'center',
                                bg: 'white',
                                width: '100%',
                                '& .PhoneInput': { pl: '20px', border: 'none !important' },
                                '& .PhoneInputInput': {
                                    border: 'none !important',
                                    height: '90%',
                                    flex: '1 1',
                                    ml: '20px',
                                    width: '100%',
                                    borderRadius: '50px',
                                    transition: 'border-color 0.2s ease',
                                    '&:focus': {
                                        outline: 'none',
                                        borderColor: 'blue', // Blue border on focus
                                    },
                                },
                                // Media query for desktop view
                                '@media (min-width: 769px)': { // Adjust the breakpoint as needed
                                    border: `1px solid #E2E8F0`, // Grey for valid, red for invalid
                                    '& .PhoneInputInput': {
                                        color: 'inherit', // Default text color for desktop
                                    }
                                },
                            }}
                        >


                            <PhoneInput
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={(value) => {
                                    setPhone(value);
                                    setIsInvalid(!value);
                                }}
                                defaultCountry="IN"
                                isInvalid={isInvalid}
                                style={{
                                    border: 'none',

                                }}
                            />
                        </Box>
                    </FormControl>
                    <Text textStyle="sm" color="fg.muted">
                        or
                    </Text>
                    <FormControl id="email" mt={-3} pt={0}>
                        <FormLabel mt={0}>Email</FormLabel>
                        <Input focusBorderColor="gray.200" ref={emailRef} type="email" placeholder="Enter your email" />
                    </FormControl>
                    <TrPrimaryButton
                        onClick={handleSignUp}
                        w="100%"
                        h={pixels["34pixels"]}
                        fontSize="15px"
                    >
                        Sign up
                    </TrPrimaryButton>
                </VStack>
            </Box>
        </Container >
    );
};

export default Signup;


