import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    VStack,
    Skeleton,
    HStack,
} from "@chakra-ui/react";
import ConversationCard from "./ConversationCard";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const SavedConversation: React.FC = () => {
    const apiUrl = 'https://us-central1-traverse-project-421916.cloudfunctions.net/conversation-memory/find_all_conversations_for_a_user';

    // State to store conversations and loading state
    const navigate = useNavigate();
    const [conversations, setConversations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const context: any = useContext(AppContext);
    const { setChatEntries, setCurrChatId, setTitle, setOnBehalf } = context;

    // Function to fetch conversations from API
    const fetchChatResponse = async (query: string) => {
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: query,
                })
            });

            if (!response.ok) {
                const text = await response.text();
                console.error('Error Response Body:', text);
                throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
            }

            const data = await response.json();
            setConversations(data); // Set the data in state
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false); // Stop loading once the API call is complete
        }
    };

    // useEffect to fetch conversations when the component is mounted
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token)
            fetchChatResponse(token);  // Fetch conversations with the token
    }, []);  // Empty dependency array means this runs once when the component is first rendered

    return (
        <VStack bg='black' minH={"100dvh"} minW={"100vw"}>
            <Box
                position={'fixed'}
                top={0}
                left={0}
                w={'100vw'}
                h={'50vh'}
                zIndex={'2'}
                bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
            />
            {/* Header */}
            <HStack
                bg={'#29252D'}
                position={"fixed"}
                top={0}
                left={0}
                w={"100vw"}
                h={'10vh'}
                pt={"-10px"}
                zIndex={10}
            >
                <Box position={'relative'} w={'100%'}>
                    <Header
                        backIconSrc="/icons/Back_Icon_White.svg"
                        heading="Saved Conversations"
                        addIconSrc="/icons/plus_outline.svg"
                        handleBackButton={() => { navigate(-1) }}
                        addButtonOnClick={() => {
                            setChatEntries([]);
                            setTitle('');
                            setCurrChatId('');
                            setOnBehalf({});
                            navigate('/chat');
                        }}
                        bgColor="#2E2B31"
                    />
                </Box>
            </HStack>

            {/* Skeleton for the entire content while loading */}
            <VStack flexDir={'column'} spacing={'20px'} zIndex={5} mt={'12vh'}  >
                {/* Search Bar */}
                {/* <SearchBar onSearch={() => { }} /> */}
                {/* Conversation List */}
                <Skeleton isLoaded={!loading}>
                    <VStack spacing={4}>
                        {conversations.map((conversation, index) => (
                            <ConversationCard
                                key={index} // Still using index as the key since there's no unique id
                                title={conversation.title} // Accessing the title from the conversation object
                                image={conversation.imageUrl} // Accessing the imageUrl from the conversation object
                                conversation_id={conversation.conversation_id} />
                        ))}
                    </VStack>

                </Skeleton>
            </VStack>
        </VStack >
    );
};

export default SavedConversation;
