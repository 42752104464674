import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import * as amplitude from '@amplitude/analytics-browser';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    Button,
    Spinner,
    Text,
    VStack
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface User {
    email: string;
    phone_number: string;
    isApproved: boolean;
    send_message?: boolean;
}

const Approve: React.FC = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const { fetchData } = useAxios();

    useEffect(() => {
        const fetchUserDetail = async () => {
            try {
                const { data } = await fetchData({
                    method: "GET",
                    url: "get/waitlist",
                    options: {
                        data: {},
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                if (data) {
                    // Add an `isApproved` field to track the approval status of each user.

                    const usersWithStatus = data.data.map((user: any) => ({
                        ...user,
                        isApproved: false,
                    }));
                    setUsers(usersWithStatus);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetail();
        // eslint-disable-next-line
    }, []);

    const handleInvite = async (email: any, phone_number: any, send_message: any) => {
        try {
            const { data, statusCode } = await fetchData({
                method: "POST",
                url: "waitlist/approve",
                options: {
                    data: { email, phone_number, send_message },
                    headers: { "Content-Type": "application/json" },
                },
                auth: false,
            });

            if (statusCode === 200) {
                // Update the specific user's approval status
                console.log("User approved:", data);
                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.email === email || user.phone_number === phone_number
                            ? { ...user, isApproved: true }
                            : user
                    )
                );
            }
        } catch (error) {
            console.error("Error sending invite:", error);
        }
    };

    return (
        <Box w="100vw" >
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minH={"100vh"}>

                    <Spinner size="xl" />
                </Box>
            ) : (
                <Table variant="simple" m={0} w="100vw" sx={{ tableLayout: "fixed" }}>
                    <TableCaption>List of Waitlist Users</TableCaption>
                    <Thead>
                        <Tr>
                            <Th w="40%">Email</Th>
                            <Th w="30%">Phone</Th>
                            <Th w="10%"></Th>
                            <Th w="20%">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users?.map((user, index) => (
                            <Tr key={index} h={"auto"} w={"100%"}>
                                <Td>
                                    <Text >{user?.email || "N/A"}</Text>
                                </Td>
                                <Td>
                                    <Text >{user?.phone_number || "N/A"}</Text>
                                </Td>
                                <Td>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setUsers((prevUsers) =>
                                                prevUsers.map((u, i) =>
                                                    i === index ? { ...u, send_message: isChecked } : u
                                                )
                                            );
                                        }}
                                    />
                                </Td>
                                <Td>
                                    {user?.isApproved ? (
                                        <Button colorScheme="green" size="sm" isDisabled>
                                            Approved
                                        </Button>
                                    ) : (
                                        <Button
                                            colorScheme="blue"
                                            size="sm"
                                            onClick={() => {
                                                amplitude.track('waitlist_signup', { approved: "true", phone: user?.phone_number ?? undefined, email: user?.email ?? undefined });
                                                handleInvite(
                                                    user?.email ?? undefined,
                                                    user?.phone_number ?? undefined
                                                    , user?.send_message ?? undefined
                                                )
                                            }}
                                        >
                                            Invite
                                        </Button>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>


            )}
            <VStack mt={4}>
                <Button
                    onClick={() => {
                        navigate("/signup", { state: { send_message: false } });
                    }}>
                    Add User
                </Button>
            </VStack>
        </Box>

    );
};

export default Approve;
