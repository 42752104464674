import React, {
  // useContext, useEffect,
  useState
} from "react";
import { Tabs, TabList, Tab, Box, TabPanel, TabPanels } from "@chakra-ui/react";

import Collection from "../../pages/Collection";
import { SearchSection } from "./SearchSection";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";
// import { AppContext } from "../../context/AppContext";

const TabNavigation: React.FC = () => {
  // const context: any = useContext(AppContext);
  // const { setIsCenterButtonClicked, isCenterButtonClicked } = context;
  const [activeTabIndex, setActiveTabIndex] = useState(0); // State to track the active tab
  const [isCollectionActive,
    //  setIsCollectionActive
  ] = useState(
    activeTabIndex === 0
  );
  // useEffect(() => {
  //   const newIndex = isCenterButtonClicked ? 1 : 0;
  //   setActiveTabIndex(newIndex);
  //   console.log('active tab index', newIndex);
  // }, [isCenterButtonClicked]);

  return (
    <>
      <Box m={0} p={0} >
        <Tabs
          variant="unstyled"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="flex-start"
          index={activeTabIndex}
          onChange={(index) => setActiveTabIndex(index)} // Update active tab index
        >
          <TabList
            bg="#29252D"
            display="flex"
            justifyContent="space-between"
            gap={1}
            position={'fixed'}
            borderRadius="99px"
            py={1}
            zIndex={5}
            px={2}
            w="94vw"
          >
            <Tab
              bg="transparent"
              color="#FFFFFFA1"
              borderRadius="full"
              fontFamily="Poppins"
              fontSize="13px"
              letterSpacing="0.5px"
              textAlign="left"
              w="45vw"
              fontWeight="500"
              // onClick={() => {
              //   setIsCenterButtonClicked(false);
              // }}
              _selected={{
                bg: "rgba(255, 255, 255, 0.1)",
                color: "#FFFFFF",
                fontWeight: "600",
              }}
              _hover={{
                bg: "rgba(255, 255, 255, 0.15)",
              }}
            >
              Ask AI for Ideas
            </Tab>

            <Tab
              bg="transparent"
              color="#FFFFFFA1"
              borderRadius="full"
              fontFamily="Poppins"
              fontSize="13px"
              letterSpacing="0.5px"
              textAlign="left"
              fontWeight="500"
              w="45vw"
              _selected={{
                bg: "rgba(255, 255, 255, 0.1)",
                color: "#FFFFFF",
                fontWeight: "600",
              }}
              _hover={{
                bg: "rgba(255, 255, 255, 0.15)",
              }}
            >
              Explore videos
            </Tab>
          </TabList>
          <TabPanels mt={'5vh'}>
            <TabPanel>
              <Collection />
            </TabPanel>
            <TabPanel>
              <SearchSection />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {/* Pass the 'isCollectionActive' prop only when the Collections tab is active */}
      <ItineraryFooter isCollectionActive={isCollectionActive} />
    </>
  );
};

export default TabNavigation;
