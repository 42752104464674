import { Tabs, TabList, Tab, HStack, Box, Text, Avatar, Flex } from '@chakra-ui/react';
import { FC, useRef, useEffect } from 'react';

interface PlacesTabsProps {
  storyIndex: number;
  onTabClick: (index: number) => void;
  allEntities: any[]; // Assuming entities have `title` and `image`
  showIndicator?: boolean; // Prop to show/hide the ellipsis indicator
}

const PlacesTabs: FC<PlacesTabsProps> = ({ storyIndex, onTabClick, allEntities, showIndicator = false }) => {
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    const tab = tabRefs.current[storyIndex];
    if (tab) {
      const tabList = tab.parentElement;
      if (tabList) {
        const tabListRect = tabList.getBoundingClientRect();
        const tabRect = tab.getBoundingClientRect();
        const offset = (tabListRect.width - tabRect.width) / 2;
  
        // Smoothly scroll the parent container to center the tab
        tabList.scrollTo({
          left: tabList.scrollLeft + tabRect.left - tabListRect.left - offset + 30,
          behavior: 'smooth',
        });
      }
    }
  }, [storyIndex]);
  

  return (
    <Box ml={2} mr={6}>
      <Tabs index={storyIndex} variant="unstyled" w={"auto"} >
        <TabList
          gap="12px"
          overflowX="auto"
          whiteSpace="nowrap"
          pl={1}
          my={0}
          pb={2}
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          }}
        >
          {allEntities?.map((entity, index) => (
            <Tab
              key={index}
              ref={(el) => (tabRefs.current[index] = el)}
              borderRadius="12px"
              m={1}
              p={showIndicator?"0":"3"}
              bg="#FFFFFF0D"
              maxW={showIndicator?"180px":"175px"}
              fontWeight="400"
              flexShrink={0}
              _selected={{ bg: "#FFFFFF21", border: showIndicator?"1px solid #9B5CFF":"1px solid #FFFFFF54", fontWeight: "700" }}
              _hover={{ bg: "#FFFFFF16" }}
              onClick={() => onTabClick(index)}
            >
              {showIndicator ? (
                <HStack spacing={3} borderRadius={"9px"} h={"62px"} pr={5}>
                  <Avatar
                    src={entity.images}

                    borderRadius={"12px 0px 0px 12px"}
                    objectFit={"cover"}
                    h={"62px"}
                    w={"62px"}
                  />
                  <Box>
                    <Text
                      fontFamily="Poppins"
                      fontSize="11px"
                      // lineHeight="16.5px"
                      textAlign="left"
                      color="#FFFFFF"
                      whiteSpace="normal"
                      noOfLines={3}
                      dangerouslySetInnerHTML={{ __html: `${entity.title}` }}
                    />
                  </Box>
                </HStack>
              ) : (
                <HStack spacing="11px">
                  <Avatar
                    src={entity.images}
                    boxSize="27px"
                    borderRadius="md"
                  />
                  <Box>
                    <Text
                      fontFamily="Poppins"
                      fontSize="11px"
                      lineHeight="16.5px"
                      textAlign="left"
                      color="#FFFFFF"
                      whiteSpace="normal"
                      noOfLines={3}
                      dangerouslySetInnerHTML={{ __html: `${entity.title}` }}
                    />
                  </Box>
                </HStack>)}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      {showIndicator && (
        <Flex justify="center" mb={"4%"} mt={"1%"}>
          {allEntities.map((_, index) => (
            <Box
              key={index}
              w="6px"
              h="6px"
              borderRadius="45%"
              mx="3px"
              bg={index === storyIndex ? "#FFFFFF" : "#FFFFFF33"}
              transition="background-color 0.3s"
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default PlacesTabs;
