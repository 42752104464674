import { Box, IconButton } from "@chakra-ui/react";
import { usePWAInstall } from '../../hooks/usePWAInstall';

interface InstallProps {
  className?: string;
}

const Install = ({ className }: InstallProps) => {
  const {
    isStandalone,
    isInstallable,
    installationStatus,
    handleInstallClick,
    isIOS,
  } = usePWAInstall({
    autoPrompt: false,
    onEvent: (eventName, data) => {
      console.log(eventName, data);
      if (eventName === 'iosinstallprompt' && isIOS) {
        alert('To install this app, tap the Share icon in Safari and select "Add to Home Screen".');
      }
    },
  });

  // Ensure the button shows for iOS, even if `isInstallable` is false
  if (isStandalone || (!isInstallable && !isIOS)) {
    return null;
  }

  return (
    <Box className={className}>
      <IconButton
        aria-label="install-pwa"
        icon={<img src="/icons/install.svg" alt="install" />}
        borderRadius="9.03px"
        color="white"
        boxSize="42px"
        bg="#2E2B31"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        _focus={{ boxShadow: "none" }}
        onClick={handleInstallClick}
        isLoading={installationStatus === 'installing'}
      />
    </Box>
  );
};

export default Install;
