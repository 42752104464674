import React from 'react';
import { Flex } from '@chakra-ui/react';
import { pixels } from '../theme';
import TrBadge from '../components/TrBadge';

interface VideoTagsProps {
	videoTags: string[];
	videoTagsLength?: number; // Optional prop to control the number of displayed tags
}

const VideoTags: React.FC<VideoTagsProps> = ({
	videoTags,
	videoTagsLength = 3,
}) => {
	return (
		<Flex mr='auto' gap={pixels['14pixels']}>
			{videoTags?.slice(0, videoTagsLength).map((tag) => (
				<TrBadge
					fontSize={'16px'}
					h={{ base: '36px', lg: `40px` }} // Reduced by 20% for 'lg'
					key={tag}>
					{tag}
				</TrBadge>
			))}
		</Flex>
	);
};

export default VideoTags;
