import React, { useContext, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	IconButton,
	Flex,
	useToast,
	useBreakpointValue,
} from '@chakra-ui/react';
import {
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	RedditIcon,
	EmailIcon,
	WhatsappIcon,
} from 'react-share';
import { AppContext } from '../context/AppContext';

const ShareModal = () => {
	const toast = useToast();
	const { shareModal, isShareModalOpen }: any = useContext(AppContext);
	const isMobile = useBreakpointValue({ base: true, md: false });

	const defaultTitle = ''; // Fallback title if the title is null

	// Automatically use `navigator.share` on mobile if supported
	useEffect(() => {
		if (
			navigator.share &&
			isMobile &&
			isShareModalOpen?.url
		) {
			navigator
				.share({
					title: defaultTitle, // Use fallback if title is null
					text:  defaultTitle,
					url: isShareModalOpen.url,
				})
				.then(() => shareModal.handleCloseModal())
				.catch(() => {}); // No action on user cancellation
		}
	}, [isMobile, isShareModalOpen, shareModal]);

	// Handle manual sharing for different networks
	const shareUsingNavigator = async (network: string) => {
		try {
			if (navigator.share && isMobile) {
				await navigator.share({
					title: defaultTitle,
					text:  defaultTitle,
					url: isShareModalOpen.url,
				});
				toast({
					title: 'Shared successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				shareModal.handleCloseModal();
			} else {
				// Fallback for unsupported browsers
				switch (network) {
					case 'Facebook':
						window.open(
							`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								isShareModalOpen?.url,
							)}`,
							'_blank',
							'width=600,height=400',
						);
						break;
					case 'Twitter':
						window.open(
							`https://twitter.com/intent/tweet?url=${encodeURIComponent(
								isShareModalOpen?.url,
							)}&text=${encodeURIComponent( defaultTitle)}`,
							'_blank',
						);
						break;
					case 'LinkedIn':
						window.open(
							`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
								isShareModalOpen?.url,
							)}&title=${encodeURIComponent(defaultTitle)}`,
							'_blank',
						);
						break;
					case 'Reddit':
						window.open(
							`https://www.reddit.com/submit?url=${encodeURIComponent(
								isShareModalOpen?.url,
							)}&title=${encodeURIComponent( defaultTitle)}`,
							'_blank',
						);
						break;
					case 'Email':
						window.location.href = `mailto:?subject=${encodeURIComponent(
							 defaultTitle,
						)}&body=${encodeURIComponent(
							`${isShareModalOpen?.url}`,
						)}`;
						break;
					case 'WhatsApp':
						window.open(
							`https://api.whatsapp.com/send?text=${encodeURIComponent(
								`${defaultTitle}: ${isShareModalOpen?.url}`,
							)}`,
							'_blank',
						);
						break;
					default:
						toast({
							title: `Sharing on ${network} is not supported.`,
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
						break;
				}
				shareModal.handleCloseModal();
			}
		} catch (error: any) {
			toast({
				title: 'Share cancelled or failed',
				description: error?.message || 'An error occurred while sharing.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			shareModal.handleCloseModal();
		}
	};

	// Define share buttons with their networks
	const shareButtons = [
		{ icon: FacebookIcon, network: 'Facebook' },
		{ icon: TwitterIcon, network: 'Twitter' },
		{ icon: LinkedinIcon, network: 'LinkedIn' },
		{ icon: RedditIcon, network: 'Reddit' },
		{ icon: EmailIcon, network: 'Email' },
		{ icon: WhatsappIcon, network: 'WhatsApp' },
	];

	return (
		<Modal
			isOpen={!navigator.share && isShareModalOpen?.url && !isMobile}
			onClose={shareModal.handleCloseModal}
			size="sm"
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Share This Post</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex justifyContent="space-around">
						{shareButtons.map(({ icon: Icon, network }) => (
							<IconButton
								key={network}
								as={Icon}
								onClick={() => shareUsingNavigator(network)}
								aria-label={`Share on ${network}`}
								variant="ghost"
								size="lg"
							/>
						))}
					</Flex>
				</ModalBody>
				<ModalFooter>
					{/* Additional footer buttons or text can go here */}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ShareModal;
