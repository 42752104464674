import { Box, Input, InputGroup, InputRightElement, SimpleGrid, Image, useDisclosure, Flex, InputLeftElement } from "@chakra-ui/react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DestinationCard from "../components/Collection-flow/DestinationCard";

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../context/AppContext";
import { useLoading } from "../context/LoadingContext";
import * as amplitude from '@amplitude/analytics-browser';
import ModalWithBackdrop from "../components/ModalWithBackdrop";
import SigninComponent from "../components/SigninComponent";
import { insertIntoDatabase } from "../services/apiService";
import { formatDateTime } from "../utilities/formatDateTime";



interface Story {
    title: string;
    city: string;
    country: string;
    continent: string;
    location: {
        lat: number;
        long: number;
    };
    image_attributions?: any;
    static_fields?: any[];
    follow_on_queries?: any;
    description: string;
    images?: string[];
    videos?: string[];
    tags: string[];
    otherDetail?: any[];
    google_maps_url?: string;
    social_links?: any;
    booking_details?: any;

}
const defaultStories: Story[] = [
    {
        title: "",
        city: "",
        country: "",
        continent: "",
        location: { lat: 0, long: 0 },
        description: "",
        tags: ["", ""],
        images: [""],
    },
];

const destinations = [
    {
        imageSrc: "/images/Iceland.png",
        subheadingText: "Northern lights spots",
        headingText: "Iceland",
        query: "Show me best spots to view Northern Lights in Iceland"
    },
    {
        imageSrc: "/images/st.png",
        subheadingText: "Best winter festivals",
        headingText: "Europe",
        query: "Best music or arts festivals in Europe in winter"
    },
    {
        imageSrc: "/images/Nightlife.png",
        subheadingText: "Best of nightlife",
        headingText: "Japan",
        query: "Best hidden, bespoke nightlife districts in Japan"
    },
    {
        imageSrc: "/images/Asia.png",
        subheadingText: "Luxury resorts in",
        headingText: "North India",
        query: "Find me the 6 most bespoke, hidden luxury resorts in North India"
    },

    {
        imageSrc: "/images/Switzerland.png",
        subheadingText: "Enchanting",
        headingText: "Switzerland",
        query: "Tell me the best romantic spots in Switzerland to experience during the winters"
    },
    {
        imageSrc: "/images/Greece.png",
        subheadingText: "Best Christmas markets in",
        headingText: "Europe",
        query: "Best Christmas markets in Europe this winter"
    }
];


const Collection: React.FC = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();
    const context: any = useContext(AppContext);
    const { setIsCollection,
        setChatEntries,
        setTitle,
        setCurrChatId,
        setOnBehalf,
        setInfluencerData,
        setCollectionLoading,
        setCollectionContent,
        setStryIndex,
        userDetail,
        isCenterButtonClicked
    } = context;
    const [searchTerm, setSearchTerm] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showSuggestedBox, setShowSuggestedBox] = useState(false);

    const setState = async (conversation_id: any, query: string, query_answer: string, entities: any) => {
        setCurrChatId(conversation_id);
        setTitle(query);
        setOnBehalf({});
        setChatEntries(
            [
                {
                    type: "Text",
                    role: "user",
                    message: query,
                    time: formatDateTime(new Date()),
                    onBehalf: undefined
                },
                {
                    type: "TextR",
                    role: "assistant",
                    message: query_answer,
                    time: formatDateTime(new Date()),
                    entities: entities.length === 0 ? undefined : entities,
                }
            ]
        );
    }

    const formatMessage = async (
        query_answer: string,
        entities: any,
        query: string,
        isCollectionCard?: boolean
    ) => {
        const token = localStorage.getItem('token');
        const conversation_id = Date.now().toString(36) + Math.random().toString(36).substr(2);
        setState(conversation_id, query, query_answer, entities);
        if (token && !isCollectionCard) {
            const format = {
                token: token,
                conversation_id: conversation_id,
                title: query,
                imageUrl: "",
                content: {
                    type: "Text",
                    message: query,
                    role: "user",
                    time: formatDateTime(new Date()),
                    onBehalf: undefined
                },
            };
            await insertIntoDatabase(format);


            const formt = {
                token: token,
                conversation_id: conversation_id,
                title: query,
                imageUrl: entities.length === 0 ? "" : entities[0].image,
                content: {
                    type: entities.length === 0 ? "Text" : "TextR",
                    message: query_answer,
                    role: "assistant",
                    time: formatDateTime(new Date()),
                    entities: entities.length === 0 ? undefined : entities,
                    onBehalf: undefined
                },
            };
            await insertIntoDatabase(formt);
        }
    }


    const convertToStories1 = (item: any, image: any): any => {
        return {
            title: item.title,
            city: item.city,
            country: item.country,
            continent: item.continent,
            location: item?.location ?? { lat: 0, long: 0 },
            description: item.description,
            images: item.images ? (item.images.length > 0 ? [image, ...item.images] : [item.image]) : [""],
            videos: undefined,
            tags: item.tags,
            image_attributions: item.image_attributions ?? undefined,
            static_fields: item?.static_fields,
            otherDetail: item.dynamic_fields,
            follow_on_queries: item?.follow_on_queries,
            google_maps_url: item?.google_maps_url,
            social_links: item?.social_links,
            booking_details: item?.booking_details
        };
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const suggestions = [
        'Vegan cafés in North Goa',
        'Adventure activities in Australia',
        'Unique culinary experiences in Japan',
    ];
    useEffect(() => {
        const handleScroll = () => {
            setShowSuggestedBox(false);
            document.getElementById("search")?.blur();

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleCollectionCard = async (query: any, isCollectionCard?: boolean) => {
        let allEntities: any[] = [];
        setStryIndex(null);
        setIsCollection(true);
        setCollectionLoading(true);
        setIsLoading(true);

        fetch(apiUrl + "v2/collection/stream", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: query,
                user_detail: isCollectionCard ? undefined : userDetail ?? undefined
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then(response => {
                if (!response.body) {
                    throw new Error('Response body is null');
                }
                const reader = response.body.getReader();
                let buffer = ""
                const readChunk = () => {
                    reader.read().then(async ({ value }) => {
                        const chunk = new TextDecoder('utf-8').decode(value);
                        buffer += chunk;

                        if (buffer === "🥚") {
                            console.log('Done reading stream');
                            return;
                        }

                        if (buffer.indexOf("✅") !== -1) {
                            let ind = buffer.indexOf("✅")
                            let comp_json = buffer.slice(0, ind)
                            buffer = buffer.slice(ind + 1)

                            console.log(`Received chunk: ${comp_json}`);
                            const message = JSON.parse(comp_json);

                            if (message.entities) {


                                formatMessage(message.query_answer, message.entities, query, isCollectionCard);
                                if (message?.entities?.length === 0) {
                                    navigate("/chat");
                                    setIsLoading(false);
                                }
                                else {
                                    allEntities = message.entities.map((entity: any) => ({
                                        ...entity,
                                        images: entity.image
                                    }));
                                    console.log("allEntities", allEntities);
                                    await setCollectionContent(Array(allEntities?.length).fill(defaultStories));
                                    await setInfluencerData(Array(allEntities.length).fill([]));
                                    const contxt = {
                                        query: query,
                                        answer: message.answer,
                                    }
                                    navigate("/story-carousel", {
                                        state: {
                                            clickedIndex: 0,
                                            isCollectionCard: isCollectionCard ?? false,
                                            iscollectionLoading: true,
                                            allEntities: allEntities, // pass the array with title and image
                                            query: query, // pass the array with title and image
                                            context: contxt,

                                        },
                                    });
                                    setIsLoading(false);
                                }
                            }
                            if (message.entities_details) {
                                setCollectionContent((prevContent: any) => {
                                    // Create a copy of the previous state
                                    const updatedContent = [...prevContent];

                                    // Update only the specific indices for matching titles
                                    allEntities.forEach((entity, index) => {
                                        const matchingDetail = message.entities_details.find(
                                            (detail: any) => detail.title === entity.title
                                        );
                                        if (matchingDetail) {
                                            updatedContent[index] = convertToStories1(matchingDetail.entity_detail, entity.images);
                                        }
                                    });

                                    return updatedContent; // Return the updated state
                                });
                            }
                        }
                        readChunk();
                    });
                };
                readChunk();
            })
            .catch((error) => {
                console.error('Error fetching stream:', error);
                setIsLoading(false);
            });
    };

    const handleSearch = () => {
        if (searchTerm.trim()) {
            amplitude.track("collection_clicked", { type: 'search_query', query_name: searchTerm, logged: localStorage.getItem('token') ? true : false });
            handleCollectionCard(searchTerm);
        }
    };
    const handleFocus = () => {
        setShowSuggestedBox(false);

    };
    // const toShow = process.env.REACT_APP_ENV as string;

    const searchBarRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isCenterButtonClicked && searchBarRef.current) {
            searchBarRef.current.focus();
        }
    }, [isCenterButtonClicked]);

    return (
        <>
            <Flex flexDir={'column'} justifyContent="center" alignItems={'center'} zIndex={9999} position={'fixed'} w={'100vw'} >
                <InputGroup mt={"2%"} px="12px"  >
                    <InputLeftElement
                        pl={6}
                        mt={1}
                        children={
                            <Image
                                boxSize={'18px'}
                                src={'/icons/Star.png'}
                                alt="search"
                                cursor="pointer"
                                onClick={handleSearch} // Trigger search on icon click
                            />
                        }
                    />
                    <Input
                        ref={searchBarRef}
                        py={'2%'}
                        id="search"
                        placeholder="Ask or browse trip ideas"
                        _placeholder={{ color: "#B0B0B0" }}
                        bg="#242127"
                        color="#FFFFFF"
                        borderRadius="12px"
                        border={'1px solid #FFFFFF12'}
                        boxShadow={'0px 1px 1px rgba(255, 255, 255, 0.32)'}
                        h={'48px'}
                        pl={9}
                        onFocus={(e) => {
                            if (localStorage.getItem('token')) {
                                setShowSuggestedBox(true)
                            }
                            else {
                                (e.target as HTMLInputElement).blur();
                                onOpen();
                            }
                        }}
                        onBlur={() => setTimeout(() => setShowSuggestedBox(false), 200)}
                        // Controlled input
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                                (e.target as HTMLInputElement).blur(); // Hide keyboard on Enter key press
                            }
                        }}
                    />
                    <InputRightElement
                        py={'25px'}
                        pr={'15px'}
                        mt={'1px'}
                        children={
                            <Image
                                boxSize={'18px'}
                                src={'/icons/Chat_send.png'}
                                alt="search"
                                cursor="pointer"
                                onClick={handleSearch} // Trigger search on icon click
                            />
                        }
                    />
                </InputGroup>
                {showSuggestedBox && (
                    <Box
                        position={'relative'}

                        bg="#242127"
                        color="gray.200"
                        zIndex={99999}
                        w={'93vw'}
                        p={2}
                        borderRadius="12px"
                        border="1px solid #FFFFFF12"
                        maxH="auto"

                        overflowY="auto"
                    >
                        {suggestions.map((suggestion, index) => (
                            <Box
                                key={index}
                                p={2}
                                fontSize={'md'}
                                // _hover={{ bg: "#3a3a3a", cursor: "pointer" }}
                                onClick={() => {

                                    amplitude.track('video_search_clicked', { type: "suggested_query", query_name: suggestion, page: 'Homepage' });
                                    handleCollectionCard(suggestion, true);
                                }}
                            >
                                {suggestion}
                            </Box>
                        ))}
                    </Box>
                )}
            </Flex>
            <Box
                mt={"21%"}
                minH="100vh"
                w="100vw"
                onScroll={() => setShowSuggestedBox(false)}
                onFocus={handleFocus}
                overflow="hidden">

                <Box
                    position="relative"
                    zIndex={1}

                    px="12px">
                    {/* {toShow === "DEV" && */}
                    {/* } */}
                    <Box mb="20%">
                        <SimpleGrid columns={2} spacing={3} alignItems={'center'} justifyContent={'center'} >
                            {destinations.map((destination, index) => (
                                <DestinationCard
                                    key={index}
                                    onClick={async () => {
                                        amplitude.track("collection_clicked", { type: 'card', query_name: destination.query, logged: localStorage.getItem('token') ? true : false });
                                        handleCollectionCard(destination.query, true)
                                    }}
                                    imageSrc={destination.imageSrc}
                                    headingText={destination.headingText}
                                    subheadingText={destination.subheadingText}
                                />
                            ))}
                        </SimpleGrid>

                    </Box>
                </Box>
            </Box>
            <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>
        </>

    );
}

export default Collection;
