import {
	Flex,
	Heading,
} from '@chakra-ui/react';
import { VideoHighlights } from './VideoHighlights';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

interface InfLocationDetailProps {
	influencerData: any;
}
const InfLocationDetail: React.FC<InfLocationDetailProps> = ({ influencerData }) => {
	const [isLiked, setIsLiked] = useState(false);
	const context: any = useContext(AppContext);
	const { likeSearches } = context;
	useEffect(() => {
		setIsLiked(false);
		for (let video of likeSearches) {
			if (video["url"] === influencerData?.video_url || video["url"].includes(influencerData?.video_id)) {
				setIsLiked(true);
			}
		}
		// eslint-disable-next-line
	}, [JSON.stringify(influencerData), likeSearches]);

	// console.log("One influencer ", JSON.stringify(influencerData));
	return (
		<Flex flexDir={'column'} minH={'100%'} overflowX={'hidden'} p={2} px={4}>
			{/* Video Highlights */}

			{influencerData?.['highlights']?.length ? (
				<><Heading

					fontFamily='Poppins'
					fontSize='20px'
					fontWeight='600'
					lineHeight='18px'
					letterSpacing='-0.10000001192092896px'
					textAlign='left'
					color={'white'}
					mb={'32px'}>
					Video Highlights
				</Heading>
					<VideoHighlights highlights={influencerData?.['highlights']} /></>
			) : (
				<Heading
					fontFamily='Poppins'
					fontSize='20px'
					fontWeight='600'
					lineHeight='25px'
					letterSpacing='-0.0000001192092896px'
					textAlign='left'
					color={'#FFFFFFB3'}
					mb={'32px'}>
					{isLiked ? "Video highlights are being generated" : "Like the video to generate highlights"}
				</Heading>)
			}

		</Flex>
	);
};
export default InfLocationDetail;
