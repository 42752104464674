import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AuthCallback: React.FC = () => {
	const navigate = useNavigate();
	const { token }: any = useParams<{ token: string }>();

	useEffect(() => {
		if (token) {
			localStorage.setItem('token', token);
			if (window.opener) {
				window.opener.localStorage.setItem('token', token);
				window.opener.location.reload();
				window.close();
			} else {
				navigate('/');
			}
		}
		else {
			navigate('/signup');
		}
	}, [token, navigate]);

	return (
		<div>
			<p>Processing authentication...</p>
		</div>
	);
};

export default AuthCallback;
