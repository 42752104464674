import { Box, Avatar, Flex, IconButton, VStack, HStack, Image, Text, Button, useDisclosure } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { pixels } from "../../theme";
import CollapsibleText from "../Text/CollapsibleText";
import TrPrimaryButton from "../TrPrimaryButton";
import { AppContext } from "../../context/AppContext";
import useAxios from "../../hooks/useAxios";
import { ToastBucketList } from "../ToastBucketList";
import { fetchEntityDetails } from "../../services/apiService";
import { useLoading } from "../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import * as amplitude from '@amplitude/analytics-browser';

interface VideoHighlightsCardProps {
    highlight: any;
    handleMap: () => void;

}

const VideoHighlightsCard: React.FC<VideoHighlightsCardProps> = ({
    highlight,
    handleMap
}) => {
    const location = useLocation();
    const pageName = location.pathname;

    const context: any = useContext(AppContext);
    const { fetchData } = useAxios();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { setIsLoading } = useLoading();
    const [showAddedToBucket, setShowAddedToBucket] = useState(false);
    const { likeEntities, setLikeEntities, entitesDetail, setEntitesDetail,
        setPreviousChatLoading,
        setInfluencerData,
        userDetail,
        setChatEntries,
        setTitle,
        setCurrChatId,
        setOnBehalf,
        setStryIndex
    } = context;
    const [isLike, setIsLike] = useState(false);

    useEffect(() => {
        // Initialize likedItems state based on previously liked entities
        setIsLike(false);
        likeEntities.forEach((entity: any) => {

            if (entity.title === highlight.title) {
                setIsLike(true);
            }
        });
        // eslint-disable-next-line
    }, [likeEntities, isLike, highlight]);


    const handleKnowMore = async (highlight: any) => {
        setStryIndex(null);
        setChatEntries([]);
        setTitle("");
        setCurrChatId("");
        setOnBehalf({});
        let country = "";
        let city = "";
        if (highlight?.city) {
            city = highlight?.city ?? "";
        }
        if (highlight?.country) {
            country = highlight?.country ?? "";
        }
        const allEntities = [{ title: highlight.title, images: highlight.image, city: highlight?.city, country: highlight?.country, entity_id: highlight?.id }];
        const clickedTitle = highlight.title;
        const curr = entitesDetail;
        const clickedIndex = 0;

        if (curr.length > 0) {
            const allMatch = allEntities.every(
                (entity: { title: any; }, index: number) => {
                    console.log("entity title ", entity.title);
                    console.log("curr title index ", curr[index]?.title);

                    return (entity.title === curr[index]?.title)
                }
            );
            console.log("Entity detail in chat is ", entitesDetail);

            if (allMatch) {
                console.log('matched')
                const initialEntityDetail = allEntities.find(
                    (entity) => entity.title === clickedTitle
                );
                setPreviousChatLoading(true);

                navigate("/story-carousel", {
                    state: {
                        clickedIndex: clickedIndex,
                        initialEntityDetail: initialEntityDetail,
                        allEntities: allEntities, // pass the array with title and image
                        city: city,
                        country: country,
                        videoHighlight: true

                    },
                });
            }
            else {
                console.log("not matched")
                setInfluencerData([]);
                setIsLoading(true);
                setEntitesDetail([]);
                try {

                    const initialEntityDetail = await fetchEntityDetails(
                        clickedTitle,
                        userDetail,
                        city,
                        country,
                        {},
                        highlight.id ?? highlight.entity_id ?? "",
                        highlight.place_metadata_id ?? ""
                    );

                    // console.log("response is:", JSON.stringify(initialEntityDetail.data));
                    setPreviousChatLoading(true);

                    navigate("/story-carousel", {
                        state: {
                            clickedIndex: clickedIndex,
                            initialEntityDetail: initialEntityDetail.data.entity,
                            allEntities: allEntities, // pass the array with title and image
                            city: city,
                            country: country,
                            videoHighlight: true

                        },
                    });
                }


                catch (error) {
                    console.error("Error fetching entity details:", error);
                }
                finally {
                    setIsLoading(false);
                }
            }
        }
        else {
            console.log("new one");
            setIsLoading(true);
            try {
                setInfluencerData([]);
                setEntitesDetail([]);

                const initialEntityDetail = await fetchEntityDetails(
                    clickedTitle,
                    userDetail,
                    city,
                    country,
                    {},
                    highlight.id ?? highlight.entity_id ?? "",
                    highlight.place_metadata_id ?? ""
                );

                // console.log("response is:", JSON.stringify(initialEntityDetail.data));
                setPreviousChatLoading(true);

                navigate("/story-carousel", {
                    state: {
                        clickedIndex: clickedIndex,
                        initialEntityDetail: initialEntityDetail.data.entity,
                        allEntities: allEntities, // pass the array with title and image
                        city: city,
                        country: country,
                        videoHighlight: true

                    },
                });
            }


            catch (error) {
                console.error("Error fetching entity details:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
    }


    const handleLike = async (item: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            console.log('this is itme : ', item);
            // Prepare the item data for likeEntities
            const body = {
                entity_id: item?.id ?? undefined,
                image: item?.image,
                city: item?.city === "" ? undefined : item?.city,
                country: item?.country === "" ? undefined : item?.country,
                title: item?.title,
                tags: item?.tags,
                description: item?.description,
                url: item?.video_url,
                location: { "lat": item?.location?.lat, "long": item?.location?.lng },
                booking_details: item?.booking_details,
                dynamic_fields: item?.dynamic_fields
            };
            try {
                const updatedLikeEntities = isLike
                    ? likeEntities.filter(
                        (likedItem: any) => likedItem.title !== item.title
                    ) // Remove if unliked
                    : [...likeEntities, body]; // Add if liked

                setLikeEntities(updatedLikeEntities);



                if (isLike) {
                    setIsLike(false);
                    amplitude.track('disLiked', {
                        type: "video", title: item?.title, location: {
                            city: item?.city === "" ? undefined : item?.city,
                            country: item?.country === "" ? undefined : item?.country
                        }, page: pageName
                    });
                    await fetchData({
                        method: "POST",
                        url: "user/dislikeEntity",
                        options: {
                            data: { ...body, token: localStorage.getItem("token") },
                            headers: { "Content-Type": "application/json" },
                        },
                        auth: false,
                    });

                }
                else {
                    setIsLike(true);
                    setShowAddedToBucket(true);
                    setTimeout(() => {
                        setShowAddedToBucket(false);
                    }, 3000);
                    amplitude.track('liked', {
                        type: "video", title: item?.title, location: {
                            city: item?.city === "" ? undefined : item?.city,
                            country: item?.country === "" ? undefined : item?.country
                        }, page: pageName
                    });
                    await fetchData({
                        method: "POST",
                        url: "user/likeEntity",
                        options: {
                            data: { ...body, token: localStorage.getItem("token") },
                            headers: { "Content-Type": "application/json" },
                        },
                        auth: false,
                    });

                }

            }
            catch (error) {
                console.error("Error while liking/disliking the item:", error);
            }

        }
        else {
            onOpen();
        }
    };

    const handleBook = (booking_details: any) => {
        window.open(booking_details?.booking_link, "_blank", "noopener,noreferrer");
    };


    return (
        <>
            <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>
            <Box
                color="white"
                borderRadius="lg"
                overflow="hidden"
                boxShadow="lg"
                w={"auto"}
            >
                <Box position="relative">
                    <Avatar
                        src={highlight?.image}
                        name={highlight?.title}
                        borderRadius="20px"
                        w="100%"
                        h="150px"
                        objectFit="cover"
                        onClick={() => {
                            amplitude.track('entity_clicked', { title: highlight?.title, page: pageName });
                            handleKnowMore(highlight)
                        }}
                    />
                    <Flex position="absolute" top="6px" right="6px">
                        <IconButton
                            aria-label="Like"
                            icon={
                                <Image
                                    src={isLike ? "/icons/likeh.svg" : "/icons/whiteLikeh.svg"}
                                    alt="like"
                                />
                            }
                            variant="ghost"
                            onClick={() => {
                                handleLike(highlight)
                            }}
                            dropShadow="0px 4px 10px rgba(0, 0, 0, 0.6)"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            _focus={{ boxShadow: "none" }}
                        />
                        {/* <IconButton
                            aria-label="Share"
                            icon={<Image src="/icons/shareh.svg" alt="share" />}
                            variant="ghost"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            _focus={{ boxShadow: "none" }}
                        /> */}
                    </Flex>

                </Box>
                <VStack
                    gap={pixels["10pixels"]}
                    alignItems={"space-between"}
                    ml="1"
                    pt={3}
                >
                    <HStack
                        justifyContent={"space-between"}
                        position={"relative"}
                        alignItems={"flex-start"}
                    >
                        <Text
                            fontFamily="Poppins"
                            fontSize="13px"
                            fontWeight="800"
                            lineHeight="16.25px"
                            textAlign="left"
                            minHeight="48.75px"
                            noOfLines={3}
                            w={"70%"}
                            color={"#FFFFFFED"}
                        >
                            {highlight?.title}
                        </Text>

                        <Box
                            display="inline-flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"
                            bg="gray.900" // You can change this to your preferred color
                            p={2} // Padding to create the circular effect
                            onClick={() => {
                                amplitude.track('button_clicked', { buttonName: 'Map Icon', title: highlight?.title });
                                handleMap()
                            }}
                        >
                            <Image
                                src="/icons/map_pin.svg"
                                alt="map icon"
                                boxSize={4}
                            />
                        </Box>
                    </HStack>
                    <CollapsibleText
                        fontFamily="Poppins"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight="17.55px"
                        textAlign="left"
                        color={"#FFFFFFA1"}
                        content={highlight?.description}
                        maxLines={4}
                    />

                    <VStack spacing={1} alignItems={"flex-start"}>
                        {highlight?.dynamic_fields &&
                            (() => {
                                const entries = Object.entries(highlight.dynamic_fields); // Convert object to array

                                const filteredEntries = entries.filter(
                                    ([key, value]) =>
                                        key !== "identity_tag" &&
                                        (Array.isArray(value) || typeof value === "string")
                                );
                                const vibesEntry = filteredEntries.find(([key]) => key === "vibes"); // Check for 'vibes'
                                const otherEntries = filteredEntries.filter(([key]) => key !== "vibes"); // Other entries
                                // If 'vibes' exists, show it first, then pick one from other entries
                                const fieldsToShow = vibesEntry
                                    ? [vibesEntry, ...otherEntries.slice(0, 1)]
                                    : otherEntries.slice(0, 2); // Otherwise, pick top two entries

                                return fieldsToShow.map(([key, value]) => (
                                    <React.Fragment key={key}>
                                        <Text
                                            noOfLines={2}
                                            fontFamily="Poppins"
                                            fontSize="10px"
                                            fontWeight="700"
                                            lineHeight="19.5px"
                                            textAlign="left"
                                            color={"#FFFFFFD4"}
                                        >
                                            {key === "vibes"
                                                ? key.slice(0, -1).replace(/_/g, " ") // Replace underscores with spaces
                                                    .replace(/^./, (char) => char.toUpperCase()) // Remove the last character (the "s")
                                                : key
                                                    .replace(/_/g, " ") // Replace underscores with spaces
                                                    .replace(/^./, (char) => char.toUpperCase())}{" "}
                                            -{" "}

                                            <Text
                                                as={"span"}
                                                fontFamily="Poppins"
                                                fontSize="10px"
                                                fontWeight="500"
                                                lineHeight="19.5px"
                                                textAlign="left"
                                                color={"#FFFFFFA1"}
                                            >
                                                {Array.isArray(value)
                                                    ? (value as string[]).join(", ") // Cast to string array
                                                    : (value as string)} {/* Cast to string */}
                                            </Text>
                                        </Text>
                                    </React.Fragment>
                                ));
                            })()}
                    </VStack>

                    <Flex gap={"8px"}>
                        <Button
                            w={"100%"}
                            h={pixels["34pixels"]}
                            variant={'ghost'}
                            fontSize={"12px"}
                            color={'#FFFFFFD4'}
                            border="1px solid #85858534"
                            onClick={() => {
                                amplitude.track('entity_clicked', { title: highlight?.title, page: pageName });
                                handleKnowMore(highlight)
                            }}
                        >
                            Know more
                        </Button>{" "}
                        {highlight?.booking_details && (
                            <TrPrimaryButton
                                onClick={() => {
                                    amplitude.track('button_clicked', { buttonName: 'Book', title: highlight?.title });
                                    handleBook(highlight?.booking_details)
                                }}
                                w={"100%"}
                                h={pixels["34pixels"]}
                                fontSize={"13px"}
                            >
                                Book
                            </TrPrimaryButton>
                        )}
                        {" "}
                    </Flex>
                </VStack>
            </Box>
            {showAddedToBucket && <ToastBucketList />}
        </>


    )
}
export default VideoHighlightsCard;
