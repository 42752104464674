import { Box, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useContext , useState , useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { pixels } from '../theme';

const BucketCard = (props) => {
	const navigate = useNavigate();
	const context = useContext(AppContext);
	const [cityPreviewImage , setCityPreviewImage] = useState('');

	const { setBucketListCard , cityImage } = context;

	useEffect(() => {
		if (props.previewImage && props.type==='place')
			setCityPreviewImage(props.previewImage);
		else if(cityImage && cityImage[props.cityName])
			setCityPreviewImage(cityImage[props.cityName]);
		else if(props.cityName==='Other')
			setCityPreviewImage(props.previewImage);

	}, [cityImage , props.type , props.cityName , props.previewImage]);

	const showCardDetails = () => {
		console.log(props.country)
		console.log(props.cityName)
		// const country = props.country===undefined ? 'Miscellaneous':props.country
		// const city = props.cityName===undefined ? 'Unknown':props.cityName
		setBucketListCard([props.country , props.cityName]);
		navigate('/bucketCardDetail',{state: {country: props.country, city: props.cityName}});
	};

	return (
		<Box
			borderRadius={{ base: pixels['20pixels'] }}
			border='3px solid rgba(49, 48, 48, 1)'
			position='relative'
			width={{ base: '158px', lg: '130px' }}
			height={{ base: '158px', lg: '130px' }}
			cursor={'pointer'}
			onClick={showCardDetails}
			m={1}
			>
			<Image
				src={cityPreviewImage}
				alt='Description'
				objectFit='cover'
				width='100%'
				height='100%'
				opacity={0.4}
				borderRadius='20px'
			/>
			<Box
				position='absolute'
				top='0'
				left='0'
				width='100%'
				height='100%'
				display='flex'
				alignItems='center'
				justifyContent='center'
				wordBreak={'break-word'}
				>
				<Text fontSize='16px' fontWeight='bold' color='white' textAlign={'center'}>
					{props.cityName}
				</Text>
			</Box>
		</Box>
	);
};

export default BucketCard;
