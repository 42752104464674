import { Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const TraverseBlackLogo: React.FC = (props: any) => {
	return (
		<Link
			textDecoration={'none !important'}
			outline={'none'}
			color='trBlack !important'
			zIndex={20}
			fontFamily='Poppins'
			fontSize={{ base: 'medium', lg: '43px' }}
			fontStyle='normal'
			fontWeight={'bold_900'}
			lineHeight='80.734px'
			as={RouterLink}
			textTransform={'uppercase'}
			to='/'
			{...props}>
			{'Rahee'}
		</Link>
	);
};

export default TraverseBlackLogo;
