import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Box,
  Text,
  HStack,
  Flex,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ListItinerary from "./ListItinerary";
import MyBucketList from "../../pages/MyBucketList";
import ItineraryFooter from "./ItineraryFooter";
import CustomModal from "../CustomModal";
import { AppContext } from "../../context/AppContext";
import useAxios from "../../hooks/useAxios";

const NavigationTabs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const context: any = useContext(AppContext);
  const navigate = useNavigate();
  const { setLikeEntities, setLikeSearches, setCityImage } = context;
  const { fetchData } = useAxios();

  const [tabIndex, setTabIndex] = useState(1);
  const [once, setOnce] = useState(true);
  const toShow = process.env.REACT_APP_ENV as string;

  // Fetch user data for likes (searches and entities)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [likeSearchesResponse, likeEntitiesResponse] = await Promise.all([
          fetchData({
            method: "POST",
            url: "user/getUserLikeSearches",
            options: {
              data: { token: localStorage.getItem("token") },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          }),
          fetchData({
            method: "POST",
            url: "user/getUserLikeEntities",
            options: {
              data: { token: localStorage.getItem("token") },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          }),
        ]);

        const likeSearchesData = likeSearchesResponse.data.data;
        const likeEntitiesData = likeEntitiesResponse.data.data;

        setLikeSearches(likeSearchesData.like_searches);
        setCityImage((prev: any) => ({ ...prev, ...likeSearchesData.city_image }));
        setCityImage((prev: any) => ({ ...prev, ...likeEntitiesData.city_image }));
        setLikeEntities(likeEntitiesData.like_entities);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (localStorage.getItem("token") && once) {
      fetchUserData();
      setOnce(false);
    }
  }, [fetchData, setLikeEntities, setLikeSearches, setCityImage, once]);

  useEffect(() => {
    console.log("tabIndex", tabIndex);
  }, [tabIndex]);

  const handleTabChange = (index: number) => {
    if (index === 2) {
      if (tabIndex !== 2) {
        setTabIndex(2); // Only update if needed
        if (toShow === "DEV") { }
        else
          onOpen(); // Open modal when navigating to 'Saved Itineraries'
      }
    } else {
      if (isOpen) {
        onClose(); // Close modal first if open
      }
      setTabIndex(1); // Switch to 'Bucketlists'
    }
  };

  const handleBackButton = () => navigate(-1);
  const handleCloseModal = () => {
    onClose(); // Close the modal first
    setTimeout(() => {
      setTabIndex(1); // Set the tab index after the modal is closed
    }, 0);
  };

  return (
    <>
      <Box>
        <VStack bg="black" minH="100dvh" w="100vw" pt={2}>
          <Box
            position="fixed"
            top={0}
            left={0}
            w="100vw"
            overflow={"auto"}
            h="100dvh"
            zIndex="2"
            // bg="linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"
            bg="#111012"

          />
          <Tabs
            isLazy
            variant="unstyled"
            zIndex={5}
            index={tabIndex}
            onChange={handleTabChange}
            defaultIndex={1}
          >
            <TabList w="100vw" position="fixed" bg="#252128" zIndex={5} left={0} top={0}>
              <Tab p="2px">
                <HStack w="100%">
                  <IconButton
                    aria-label="Back"
                    icon={<img src="/icons/Back_Icon_White.svg" alt="back" />}
                    colorScheme="gray"
                    size="lg"
                    variant="link"
                    onClick={handleBackButton}
                  />
                </HStack>
              </Tab>
              <Tab
                sx={{ borderBottom: "1px solid #FFFFFF21", w: "100vw" }}
                _selected={{ borderBottom: "2px solid #FFFFFFED" }}
              >
                <Flex direction="column" alignItems="flex-start">
                  <Text color="#FFFFFF54" fontFamily="Poppins" fontSize="14px" fontWeight="500">
                    Your
                  </Text>
                  <Text color="#FFFFFFED" fontFamily="Poppins" fontSize="14px" fontWeight="700">
                    Bucketlists
                  </Text>
                </Flex>
              </Tab>
              <Tab
                sx={{ borderBottom: "1px solid #FFFFFF21", w: "100vw" }}
                _selected={{ borderBottom: "2px solid #FFFFFFED" }}
              >
                <Flex direction="column" alignItems="flex-start">
                  <Text color="#FFFFFF54" fontFamily="Poppins" fontSize="14px" fontWeight="500">
                    Saved
                  </Text>
                  <Text color="#FFFFFFED" fontFamily="Poppins" fontSize="14px" fontWeight="700">
                    Itineraries
                  </Text>
                </Flex>
              </Tab>
            </TabList>
            <TabPanels mt={10}>
              <TabPanel />
              <TabPanel>
                <MyBucketList />
              </TabPanel>
              <TabPanel>
                <ListItinerary />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Box mt={20} />
        </VStack>
        <ItineraryFooter />
      </Box>
      {/* Modal */}
      <CustomModal isOpen={isOpen} onClose={handleCloseModal} />
    </>
  );
};

export default NavigationTabs;