import { Box, Image, Text } from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { pixels } from '../theme';
import { Link } from 'react-router-dom';
import TrTealButton from '../components/Buttons/TrTealButton';

const Homepage = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignContent: 'center',
				alignItems: 'center',
				// gap: pixels['100pixels'],
				position: 'relative',
			}}
			className='homepage-container'>
			<Carousel
				className='homepage-carousel'
				showArrows={false}
				showIndicators={true}
				showStatus={false}
				infiniteLoop={true}
				autoPlay={true}
				interval={10000}>
				{['bg_3.jpeg', 'bg_2.webp', 'bg_1.jpeg'].map((image, index) => (
					<Box position='relative' key={index}>
						<Image
							height={{
								base: 'calc(100vh - 41px)',
								md: 'calc(100vh - 41px)',
								lg: '100vh',
							}}
							alt={`image bg ${index + 1}`}
							src={`images/bg_images/${image}`}
						/>
						<Box
							position='absolute'
							top='0'
							left='0'
							width='100%'
							height='100%'
							bg='rgba(0, 0, 0, 0.6)' // Dark overlay
						/>
					</Box>
				))}
			</Carousel>

			{/* Text content over the images */}
			<Box
				position='absolute'
				top='0'
				left='0'
				width='100%'
				height='100%'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				color='white'
				textAlign='center'
				px={{ base: '20px', md: pixels['50pixels'], lg: pixels['100pixels'] }}>
				<Text
					fontSize={{
						'base': '24px',
						'md': '22px',
						'lg': '40px',
						'2xl': '50px',
					}}
					color='white'>
					Discover a new way to travel, tailored just for you.
				</Text>
				<Text
					color='white'
					fontSize={{ base: '14px', md: '14px', lg: '20px' }}
					mt={4}>
					Rahee is your AI travel partner, designed to help you explore,
					plan, and enjoy custom-tailored travel.
				</Text>

				<TrTealButton
					mt={{ base: pixels['40pixels'], md: pixels['40pixels'] }}
					_hover={{
						opacity: 0.7,
					}}
					_active={{
						opacity: 0.5,
					}}
					as={Link}
					//@ts-ignore
					to='/join'
					zIndex={100}
					color='white'>
					Join The waitlist
				</TrTealButton>
			</Box>
		</Box>
	);
};

export default Homepage;
