import { Box, IconButton, Image } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import InfluencerVideo1 from "./InfluencerVideo1";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useLoading } from "../../context/LoadingContext";

const BackButton: React.FC = () => {
	const navigate = useNavigate();
	const routerLocation = useLocation();

	// const location = useLocation();
	// const prevLocation = useRef(null);

	// Update the ref with the current location on each render
	// useEffect(() => {
	// 	//@ts-ignore
	// 	prevLocation.current = location.pathname;
	// }, [location]);

	return (
		<IconButton
			position='fixed'
			aria-label='back'
			top='4%'
			left='3%'
			zIndex={11}
			icon={<Image src='/icons/Back_Icon_White.svg' alt='back' />}
			variant='ghost'
			size='lg'
			onClick={() => {
				if (routerLocation.key === "default") {
					navigate("/"); // Navigate to root if there is no history
				} else {
					navigate(-1); // Navigate back in history
				}

			}}
			borderRadius='full'
			dropShadow='0px 4px 10px rgba(0, 0, 0, 0.6)'
			_hover={{ bg: 'transparent' }}
			_active={{ bg: 'transparent' }}
			_focus={{ boxShadow: 'none' }}
		/>
	);
};

const InfluencersVideo: React.FC = () => {

	const { fetchData } = useAxios();
	const [showBackButton, setShowBackButton] = useState(true);
	const { setIsLoading } = useLoading();

	const [influencers, setInfluencers] = useState<any>({
		channelName: '',
		chapter_segment: '[]',
		highlights: [],
		score: 0.0,
		source: '',
		tags: [],
		video_url: '',
		preview_image: '',
		video_play_url: {
			title: '',
			is_audio: false,
			author: '',
			source: '',
			previewImage: '',
			media_url: '',
		},
	});
	const [currentIndex, setCurrentIndex] = useState(0);
	const location = useLocation();
	const clicked = location.state?.clicked ?? "";

	const influencerDataState = location.state?.influencerData ?? {
		channelName: '',
		chapter_segment: '[]',
		highlights: [],
		score: 0.0,
		source: '',
		tags: [],
		video_url: '',
		video_play_url: {
			title: '',
			is_audio: false,
			author: '',
			source: '',
			previewImage: '',
			media_url: '',
		},
	};




	const queryString = location.search.slice(1); // Remove the '?' from the query string
	const params = new URLSearchParams(queryString);

	// Get the base `link` value
	let link = params.get('link');

	// Check if additional parameters exist (e.g., 'v' belongs to 'link')
	if (link && queryString.includes('v=')) {
		const remainingQuery = queryString.split('link=')[1]; // Extract everything after 'link='
		link = decodeURIComponent(remainingQuery); // Decode the full reconstructed value
	}

	// console.log("I am here", link);


	const fetchVideoDetail = async () => {
		const { data, statusCode, error } = await fetchData({
			method: 'POST',
			url: 'ai/video-link-data',
			options: {
				data: {
					videos: [link],
				},
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});

		if (statusCode === 200) {
			if (data && data.videos) {

				window.scrollTo(0, 0);
				await setInfluencers(data.videos);
				setIsLoading(false);
			}
		} else {
			console.log(error);
		}
	};
	useEffect(() => {
		if (!link) {
			const index = influencerDataState.findIndex((item: any) => item?.video_url === clicked?.video_url);
			setCurrentIndex(index !== -1 ? index : 0);
		}
		// eslint-disable-next-line
	}, [influencerDataState]);

	useEffect(() => {
		// alert(link);
		if (link) {
			setIsLoading(true);
			console.log("I am here111111")
			fetchVideoDetail();
			setCurrentIndex(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [link]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const viewportHeight = 60;

			// console.log(`Scroll Position: ${scrollPosition}`);
			// console.log(`Viewport Height: ${viewportHeight}`);

			if (scrollPosition > viewportHeight) {
				console.log('Hiding back button');
				setShowBackButton(false);
			} else {
				console.log('Showing back button');
				setShowBackButton(true);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);




	return (
		<Box>
			{showBackButton && <BackButton />}
			<InfluencerVideo1
				influencers={link ? influencers : influencerDataState}
				currentIndex={currentIndex}
				isLink={link === null ? false : true}
				handleNext={() => {
					setCurrentIndex((prev) => (prev + 1) % influencerDataState.length);
				}}
				handlePrevious={() => {
					setCurrentIndex((prev) => (prev - 1 + influencerDataState.length) % influencerDataState.length)
				}}

			/>
		</Box>

	)

};
export default InfluencersVideo;