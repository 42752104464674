import {
  Flex,
  Divider,
  Box,
  Heading,
  VStack,
  Text,
  Input,
  Image,
  Container,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import MyCountryBucket from "../components/MyCountryBucket";
import { pixels } from "../theme";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";

const MyBucketList = () => {
  const context = useContext(AppContext);
  const { likeSearches, likeEntities } = context;
  const [countries, setCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const uniqueCountries = new Set();
    console.log("like Searches, ", likeSearches);
    console.log("like Entities, ", likeEntities);

    likeSearches.forEach((item) => {
      if (item.countries && Object.keys(item.countries).length > 0) {
        Object.keys(item.countries).forEach((countries) =>
          uniqueCountries.add(countries)
        );
      } else {
        uniqueCountries.add(undefined);
      }
    });

    console.log("uniqueContries ", uniqueCountries);

    likeEntities.forEach((item) => {
      uniqueCountries.add(item.country);
    });

    let sortedCountries = Array.from(uniqueCountries);

    const hasUndefined = sortedCountries.includes(undefined);

    sortedCountries = sortedCountries
      .filter((country) => country !== undefined)
      .sort();

    if (hasUndefined) {
      sortedCountries.push(undefined); // Fallback to end if no match found
    }

    setCountries(sortedCountries);

    console.log(
      "this is my sorted countries with undefined preserved: ",
      sortedCountries
    );
  }, [likeSearches, likeEntities]);

  const filteredItems = countries?.filter((country) => {
    if (country === undefined) {
      return "Miscellaneous".toLowerCase().includes(searchQuery.toLowerCase());
    }
    return country?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <Flex
      overflow={"auto"}
      flexDir={"column"}
      alignItems={"center"}
      // bg="#111012"
      pt={{ base: 0, lg: pixels["50pixels"], "2xl": pixels["65pixels"] }}
      pb={pixels["150pixels"]}
      w="100%"
    >
      <Flex
        flexDir={"column"}
        mt={{ base: 0, lg: "90px", "2xl": "120px" }}
        maxW={{
          base: "100%",
          md: pixels["700pixels"],
          lg: pixels["894pixels"],
          "2xl": pixels["1100pixels"],
        }}
        gap={pixels["30pixels"]}
        mx="auto"
        w={"100%"}
      >
        <Box pt={{ base: pixels["20pixels"], lg: "60px" }} w={"100%"}>
          <VStack width={"100%"}>
            <Container display={["none", "none", "contents"]}>
              <Heading
                display={{ base: "none", lg: "inherit" }}
                w={"100%"}
                color={"white"}
              >
                Your Bucketlist
              </Heading>
              <Heading
                display={{ base: "flex", lg: "none" }}
                flexDir={"column"}
                w={"100%"}
                color={"white"}
              >
                <Text fontSize={"13px"} color="#9D9D9D">
                  Your
                </Text>
                <Text fontSize={"13px"} fontWeight={900}>
                  Bucketlist
                </Text>
              </Heading>
              <Divider pt={"10px"} pb={{ base: "0", lg: "20px" }} />
            </Container>
            <Box
              w="100%"
              position={"fixed"}
              left={0}
              pt={20}
              top={0}
              zIndex={1}
              px={4}
              pb={2}
              bg="linear-gradient(180deg, #252128 3%, rgba(36, 29, 50, 0) 80%)"
            >
              <InputGroup>
                <Input
                  placeholder="Search destinations by country"
                  _placeholder={{ color: "#B0B0B0" }}
                  bg="#242127"
                  color="#FFFFFF"
                  borderRadius="12px"
                  border={"1px solid #FFFFFF12"}
                  h={"48px"}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <InputRightElement
                  py={"25px"}
                  mt={"1px"}
                  children={
                    <Image
                      boxSize={"18px"}
                      src={"/icons/search2.svg"}
                      alt="search"
                      cursor="pointer"
                    />
                  }
                />
              </InputGroup>
            </Box>
            <Box mt={16} w={"100%"}>
              {countries?.length > 0 ? (
                filteredItems?.length > 0 ? (
                  filteredItems.map((country, index) => {
                    return (
                      <MyCountryBucket
                        key={index}
                        country={country}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <Text color="#9D9D9D" fontSize={"16px"}>
                    No countries found
                  </Text>
                )
              ) : (
                <Text
                  color="rgba(255, 255, 255, 0.45)"
                  textAlign="center"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="133%"
                  mt={"50%"}
                >
                  ✨ Your bucket list is looking a little empty! Start liking
                  your favourite spots or send us a video on WhatsApp 📲 to save
                  the places you can't wait to explore. 🌍
                </Text>
              )}
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MyBucketList;
