import { Heading, HStack, IconButton, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import Install from "./Install";

interface HeaderMobileProps {
  isAuthenticated: boolean;
  onOpenLogin: () => void;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ isAuthenticated, onOpenLogin }) => {
  const navigate = useNavigate();
 

  return (
    <HStack
      bg="#29252D"
      px={6}
      py={3}
      w="100vw"
      justifyContent="space-between"
      borderBottomRadius="33px"
    >
      {/* Logo and Title */}
      <HStack>
        <Image src="/icons/logo.svg" alt="logo" boxSize="18px" />
        <Heading
          fontFamily="Poppins"
          fontSize="16.78px"
          fontWeight="600"
          lineHeight="20.28px"
          letterSpacing="-0.21px"
          textAlign="left"
          onClick={() => navigate("/")}
          color="#FFFFFFED"
          cursor="pointer"
        >
          Rahee AI
        </Heading>
      </HStack>

      {/* Action Buttons */}
      <HStack>
        <Install />

        {/* Saved Chat Button */}
        <IconButton
          aria-label="saved-chat"
          icon={<img src="/icons/chat.svg" alt="chat" />}
          borderRadius="9.03px"
          color="white"
          bg="#2E2B31"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
          onClick={() => {
            amplitude.track("button_clicked", { buttonName: "saved-chat" });
            if (isAuthenticated) {
              navigate("/savedConversation");
            } else {
              amplitude.track("login_triggered", { buttonName: "saved-chat", page: "HomePage" });
              onOpenLogin();
            }
          }}
        />

        {/* Profile or Login Button */}
        {isAuthenticated ? (
          <Image
            src="/icons/profile2.png"
            alt="user"
            boxSize="42px"
            objectFit="cover"
            onClick={() => navigate("/profile")}
            cursor="pointer"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
          />
        ) : (
          <IconButton
            aria-label="log-in"
            icon={<img src="/icons/user.svg" alt="user" />}
            borderRadius="9.03px"
            color="white"
            bg="#2E2B31"
            onClick={() => {
              amplitude.track("login_triggered", { buttonName: "log-in", page: "HomePage" });
              onOpenLogin();
            }}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
          />
        )}
      </HStack>
    </HStack>
  );
};

export default HeaderMobile;
